<template>
  <div class="form-content">
    <div class="form-group">
      <div class="form-group_item">
        <label>员工编号:</label>
        <span>{{data.jobNumber}}</span>
      </div>
      <div class="form-group_item">
        <label>姓名:</label>
        <span>{{data.staffName}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>聘用部门:</label>
        <span>{{data.sysDeptName?data.sysDeptName.deptName:''}}</span>
      </div>
      <div class="form-group_item">
        <label>聘用岗位:</label>
        <span>{{data.sysPositionNameList&&data.sysPositionNameList.length?data.sysPositionNameList.map((e) => e.positionName).join(","):''}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>试用开始日期:</label>
        <span>{{data.beginTrailTime}}</span>
      </div>
      <div class="form-group_item">
        <label>试用截止日期:</label>
        <span>{{data.endTrailTime}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>迟到次数:</label>
        <span>{{data.lateCount}}</span>
      </div>
      <div class="form-group_item">
        <label>早退次数:</label>
        <span>{{data.earlyCount}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>矿工天数:</label>
        <span>{{data.absenteeismDay}}</span>
      </div>
      <div class="form-group_item">
        <label>事假天数:</label>
        <span>{{data.personalLeaveDay}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>病假天数:</label>
        <span>{{data.sickLeaveDay}}</span>
      </div>
      <div class="form-group_item">
        <label>试用期目标考核得分:</label>
        <span>{{data.targetAssessmentScore}}</span>
      </div>
    </div>

    <div class="form-group">
      <div class="form-group_item">
        <label>综合素质评估得分:</label>
        <span>{{data.comprehensiveQualityScore}}</span>
      </div>
      <div class="form-group_item">
        <label>转正述职答辩得分:</label>
        <span>{{data.lectureReportScore}}</span>
      </div>
    </div>
    <div class="form-group">
      <div class="form-group_item">
        <label>本次合同主体:</label>
        <span>{{data.contractsPartA}}</span>
      </div>
      <div class="form-group_item">
        <label>审批类型:</label>
        <span>{{data.activityKeyName||''}}</span>
      </div>
    </div>
    <div class="detail-status status" v-if="data.status!==undefined" :class=[$reconava.getStatusClass(data.status)]>
           <span class="status-name">
          {{$reconava.getStatusName(data.status)}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "positive-form-info",
  data(){
    return {

    }
  },
  props:['data']
}
</script>

<style scoped lang="scss">
@import "../../common-style/form-info";
</style>